@import "../../../layout/common.scss";

.MainNavWrap {
  margin-bottom: 8%;

  .navRow {
    display: flex;
    justify-content: center;

    .navItemWrap {
      position: relative;
      overflow: hidden;
      width: 100%;
      max-width: 539px;
      height: 100%;
      max-height: 300px;
      padding: 65px 80px;
      border-radius: 16px;
      box-shadow: 0 1px 16px 0 $primary-010, 0 2px 4px 0 rgba(51, 51, 51, 0.12);
      background-color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;

      &:nth-child(2) {
        margin-left: 2%;
      }
      /*Kevin add the gap space between third and forth component*/
      &:nth-child(3) {
        margin-left: 2%;
      }

      &:nth-child(4) {
        margin-left: 2%;
      }
    }
    &:nth-child(2) {
      margin-top: 2%;
    }
  }
}

.title {
  font-size: 1vw; /* Kevin Change 2 to 1 */
  font-weight: 600;
  line-height: 2vw;
  margin: 22px auto;
  letter-spacing: 2px;
  text-align: center;
  color: $dark-075;
  white-space: nowrap;
}
.content {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: $dark-050;
  text-align: center;
}
.backgroundImg {
  position: absolute;
  bottom: -13%;
  right: 8%;
  opacity: 0.1;
}

.navItemWrap:hover {
  box-shadow: 0 1px 16px 0 $primary-010, 0 2px 4px 0 rgba(51, 51, 51, 0.12);
  background-image: linear-gradient(
    119deg,
    #40b0f4,
    #0087ff 48%,
    $primary-100 100%
  );

  // changing icon color
  > svg {
    color: $white !important;
  }

  // changing background icon color
  .backgroundImg span {
    color: $white !important;
  }
  .title {
    color: $white;
  }
  .content {
    color: $white;
  }
}
@media (max-width: 880px) {
  .content {
    display: none;
  }
  .backgroundImg {
    display: none;
  }
  .title {
    font-size: 4vw;
    text-align: center;
  }
  .navItemWrap {
    padding: 10px 0px !important;
  }
}
