@import "../../../layout/common.scss";

.apoBox {
  width: 100%;
  min-width: 200px;
  height: 226px;
  padding: 20px 20px 24px;
  border-radius: 16px !important;
  box-shadow: 0 1px 16px 0 $primary-010, 0 2px 4px 0 rgba(51, 51, 51, 0.12);
  background-color: $white;
}

.datetime {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  color: $dark-050;
  margin-bottom: 8px;
}
.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: $primary-100;
  margin-bottom: 8px;
}
.addressWrap {
  margin-bottom: 4px;
}
.departmentWrap {
  margin-bottom: 16px;
}
.addressLabel,
.departmentLabel {
  font-size: 14px;
  color: $dark-033;
  margin: 0px;
}
.address,
.department {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: $dark-050;
  margin-bottom: 5px;
}
.moreBtn {
  display: block;
  margin: 0 auto;
  width: 110px;
  height: 32px;
  border-radius: 30px;
  border: solid 1px var($dark-050);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  text-align: center;
  color: $dark-050;
  box-shadow: 0 1px 16px 0 $primary-010, 0 2px 4px 0 rgba(51, 51, 51, 0.12);
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    border: solid 1px $secondary-100;
    color: $secondary-100;
  }
}
