@import "../../../layout/common.scss";

.recBox {
  width: 256px;
  height: 325px;
  border-radius: 16px;
  box-shadow: 0 1px 16px 0 $primary-010, 0 2px 4px 0 rgba(51, 51, 51, 0.12);
  background-color: $white;
  overflow: hidden;
  transition: 0.3s;
  figure img {
    width: 100%;
    height: 152px;
    object-fit: cover;
  }
  &:hover {
    box-shadow: 0px 0px 0px 1px $secondary-100;
  }
}
.contentWrap {
  padding: 0px 20px 24px 20px;

  .title {
    font-size: 16px;
    height: 48px;
    overflow: hidden;
    font-weight: 600;
    color: $primary-100;
    margin-bottom: 5px;
  }
  .content {
    font-size: 14px;
    height: 62px;
    overflow: hidden;
    color: $dark-033;
    margin-bottom: 8px;
  }
  .moreBtn {
    font-size: 12px;
    line-height: 1.5;
    text-align: right;
    color: $secondary-100;

    span {
      cursor: pointer;
    }
  }
}
