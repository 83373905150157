@import "../../../layout/common.scss";

.companyBox {
  width: fit-content !important;
  height: fit-content !important;
  padding: 16px 20px;
  border-radius: 16px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: 0 1px 16px 0 $primary-010, 0 2px 4px 0 rgba(51, 51, 51, 0.12);
    background-color: $white;
  }
  .industry {
    border-radius: 30px;
    background-color: $dark-033;
    box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: $white;
    padding: 1px 15px;
  }

  figure {
    margin-top: 16px;

    img {
      width: 100%;
      vertical-align: middle;

      height: 1.5rem;
      object-fit: contain;
      margin: auto auto;
    }
  }
  .companyName {
    font-size: 30px;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    color: $dark-100;
    margin: 0;
    margin-top: 16px;
  }
}
