@import "../../../layout/common.scss";
.footerWrap {
  max-width: 100%;
  min-height: 243px;
  height: auto;
  box-sizing: border-box;
  box-shadow: 0 1px 16px 0 $primary-100, 0 2px 4px 0 rgba(51, 51, 51, 0.12);
  background-image: linear-gradient(
    100deg,
    #40b0f4,
    #0087ff 48%,
    $primary-100 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerContent {
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.bizApoInfo {
  display: flex;
  flex-direction: column;
  min-width: 260px;

  .bizApoTitle {
    font-size: 24px;
    font-weight: 600;
    color: white;
    margin: 0 0 8px 0;
    text-align: left;
  }
  a {
    font-size: 13px;
    line-height: 25px;
    color: white;
    text-decoration: none !important;
    transition: 0.3s;
    &:hover {
      color: $secondary-100;
    }
  }
  p {
    font-size: 12px;
    font-weight: 600;
    line-height: 28px;
    color: white;
    opacity: 0.5;
  }
}

.infoBoxWrap {
  margin-top: 6px;
  display: flex;
  align-items: flex-start;
  transition: 0.3s;
}

.infoBox {
  display: flex;
  flex-direction: column;
  min-width: 200px;

  h2 {
    opacity: 0.5;
    font-size: 12px;
    font-weight: 600;
    color: white;
    line-height: 26px;
  }
  a {
    font-size: 13px;
    line-height: 25px;
    color: white;
    text-decoration: none !important;
    transition: 0.3s;

    &:hover {
      color: $secondary-100;
    }
  }
}

@media (max-width: 680px) {
  .footerWrap {
    padding: 20px;
  }
  .bizApoInfo {
    margin-bottom: 25px;
  }
  .infoBoxWrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .infoBox {
    margin-bottom: 15px;
  }
}
