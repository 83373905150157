@import "../../layout/common.scss";

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slideIndexWrap {
  display: flex;
  align-items: center;
  span {
    width: 32px;
    height: 32px;
    line-height: 32px;
    color: $dark-075;
    cursor: pointer;
  }
}
.slideIndex {
  font-size: 24px;
  font-weight: 600;
  color: $dark-075;
}

@media (max-width: 479px) {
  .titleWrap {
    flex-direction: column;
  }
}
