$primary-100: #1e78e6;
$primary-010: rgba(30, 120, 230, 0.08);
$primary--00-2-: #008cd2;
$secondary-100: #ff9e46;
$yellow-100: #ffc846;
$green-dark-100: #2a9d8f;
$screen: #fafafa;
$white: #ffffff;
$dark-100: #333333;
$dark-075: rgba(51, 51, 51, 0.75);
$dark-050: rgba(51, 51, 51, 0.5);
$dark-033: rgba(51, 51, 51, 0.33);
$dark-010: rgba(51, 51, 51, 0.1);
$danger: #e62828;

.commonTitle {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  color: $dark-075;
  margin-bottom: 40px;

  &::after {
    left: 0px;
    bottom: -16px;
    position: absolute;
    content: "";
    width: 100px;
    height: 4px;
    // margin: 16px 0 0;
    background-color: $primary-100;
  }
}
