body {
  margin: 0;

  font-family: YakuHanJPs, \\6e38\30b4\30b7\30c3\30af, YuGothic,
    \\30d2\30e9\30ae\30ce\89d2\30b4 ProN W3, Hiragino Kaku Gothic ProN,
    \\30e1\30a4\30ea\30aa, Meiryo, sans-serif;
  background-color: #fafafa;
}
* {
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;

  /* remove the blue highlight of button on mobile? */
  -webkit-tap-highlight-color: transparent;
}
*:focus,
*:active {
  outline: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* *::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 6px;
} */

*::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 6px;
}
